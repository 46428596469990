import React from 'react';

import Layout from 'components/layout';
import Seo from 'components/seo';
import Success from 'sections/success'

const SuccessPage = () => {

  return(
    <Layout>
      <Seo title="Thank You!" />
      <Success/>
    </Layout>
  );
}

export default SuccessPage;