/** @jsx jsx */
import { jsx, Box, Grid, Container, Button, Heading, Text } from 'theme-ui';
import { HiOutlineChevronRight } from 'react-icons/hi';

const Success = () => {

  const styles = {
    section: {
      pt: [8, null, null, null, 10, 0],
      pb: [8, null, null, null, 10, 0],
    },
    container: {
      px: [3, null, null, 6],
    },
    grid: {
      alignItems: ['center'],
      gridTemplateColumns: ['1fr', null, null, null, '1fr 1fr'],
      minHeight: [null, null, null, null, '60vh', '100vh'],
      pt: [15, null, null, 17, 12],
    },
    content: {
      textAlign: ['center', null, null, null, 'left'],
      h1: {
        color: 'text',
        fontWeight: 'bold',
        fontSize: [8, null, null, null, 12, 14, 15],
        lineHeight: [1.1, null, null, null, 1.2],
        letterSpacing: 'heading',
      },
      p: {
        color: 'textSecondary',
        fontSize: [2, null, null, 4],
        lineHeight: 1.5,
        maxWidth: 480,
        mx: [null, null, null, 'auto', 'unset'],
        marginTop: [1, null, null, 3],
      },
    },
    newsUpdate: {
      backgroundColor: 'muted',
      boxShadow: '0px 2px 8px rgba(67, 99, 136, 0.07)',
      borderRadius: 50,
      cursor: 'pointer',
      padding: '4px 15px 4px 5px',
      display: 'inline-flex',
      alignItems: 'center',
      color: 'textSecondary',
      fontSize: ['12px', null, null, 1],
      mb: 4,
      textAlign: 'left',
      span: {
        backgroundColor: 'primary',
        color: 'white',
        fontSize: ['10px', null, null, '13px'],
        fontWeight: 700,
        display: 'inline-flex',
        minHeight: 24,
        alignItems: 'center',
        px: '11px',
        borderRadius: 50,
        mr: '12px',
      },
    },
    buttonGroup: {
      mt: [6, null, null, null, 10],
      button: {
        minHeight: 45,
        marginRight: 5,
        marginTop: [5, null],
        px: ['17px', 4],
        svg: {
          transition: 'margin-left 0.3s ease-in-out 0s',
          pl: '3px',
        },
        ':hover': {
          svg: {
            pl: '5px',
          },
        },
      },
      '.white': {
        boxShadow: '0px 4px 6px rgba(125, 128, 170, 0.08)',
        borderRadius: 5,
        fontWeight: 500,
        ml: 3,
      },
    },
    illustration: {
      maxWidth: [null, null, null, '80%', '100%'],
      mx: [null, null, null, 'auto', 'unset'],
      mt: [6, null, null, null, 0],
      image: {
        width: ['100%']
      }
    },
  };
  


  return (
    <section id="home" sx={styles.section}>
      <Container sx={styles.container}>
        <Grid sx={styles.grid}>
          <Box sx={styles.content}>
            <Heading as="h1">
              Thank you for your purchase!
            </Heading>
            <Text as="p">
              Let's Get Started!
            </Text>
            <div sx={styles.buttonGroup}>
              <Button sx={styles.button} variant="primary" onClick={() => window.location.href="https://docgen.trelent.net/#/app/start"}>Onward To The Dashboard!<HiOutlineChevronRight/></Button>
            </div>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <img
              src={"../undraw_deal.svg"}
              sx={styles.illustration.image}
              alt={"A woman and a man shaking hands and holding business documents."}
            />
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Success;
